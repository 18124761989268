<template>
    <div class="container">
        <header class="jumbotron">
            <h3>
                {{this.currentUser.userName}} zmień hasło
            </h3>
        </header>
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-6">
                <label for="password"><strong>Nowe hasło:</strong> </label>
                <InputText id="password" type="password" v-model="content.password" placeholder="Hasło"/>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <label for="passwordConfirm"><strong>Powtórz hasło:</strong> </label>
                <InputText id="zipCode" type="password" v-model="content.passwordConfirm" placeholder="Powtórz hasło"/>
            </div>
            <div class="p-field p-col-12 p-md-6 fa-pull-right">
                <Button type="button" label="Zmień hasło" v-on:click="click"/>
            </div>
        </div>
    </div>
</template>

<script>
    import UserService from "../../services/user.service";
    import TokenService from "../../services/token.service";

    export default {
        name: 'ChangePassword',
        props: {
            initialCntent: {
                // eslint-disable-next-line vue/require-valid-default-prop
                password: 'null',
                passwordConfirm: 'null'
            }
        },
        data() {
            return {
                content: {
                    password: null,
                    passwordConfirm: null
                },
                currentUser: TokenService.getParseLocalAccessToken()
            };
        },
        methods: {
            click: function () {
                UserService.chongePassword(this.content, this.$route.params.roleId).then(
                    () => {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Sukces',
                            detail: 'Zmieniono hasło',
                            life: 3000
                        });
                    },
                    (error) => {
                        this.$toast.add({
                            severity: 'error',
                            summary: error.response.data.error,
                            detail: error.response.data.message,
                            life: 3000
                        });
                    }
                );
            }
        }
    };
</script>
